@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
div,
li {
  font-family: 'Inter', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  background-color: #DEEBE9;
}
.inner-container {
  padding-bottom: 24px;
}

.inner-container .contact {
  padding-top: 0px;
}

.inner-container h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 20px;
}
p.contact-quote {
	text-align: center;
	margin-bottom: 30px;
}
.address,
.email,
.phone {
	background: #ffffff;
	padding: 40px;
	text-align: center;
	border-radius: 5px;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;

}

.address {
	margin-top: 10px;
}

.address p,
.email p,
.phone p {
	margin: 0
}

.address h3,
.email h3,
.phone h3 {
	font-weight: 700;
}
.anchor-text {
	text-decoration: none;
	color: #212529;
}
.box-with-shadow {
	background: #ffffff;
	padding: 35px;
	text-align: center;
	border-radius: 5px;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
}
section.contact .btn-primary {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: inherit;
  line-height: 24px;
  text-transform: none;
}
section.contact form {
  text-align: left;
}
.address i,
.email i,
.phone i {
	font-size: 40px;
	color: #0d53ad;
	margin-bottom: 20px;

}

.inner-container h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #0d53ad;
  bottom: 0;
  left: calc(50% - 25px);
}

.sticky {
 
}
.card-main {
  margin-bottom: 20px;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  Height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 4px 9px 0px #00000040;
  padding: 0px 26px;
}
.header nav {
    padding: 26px 0px;
}
.table-responsive::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.btn-primary {
  padding-right:25px;
  padding-left:25px;
  min-height: 57px;
  background-color: #00A295;
  border-radius: 5px;
  color: #FFF;
  border: none;
  font-size: 23px;
  font-weight: 700;
  transition: box-shadow 0.3s ease; /* Add transition for smooth effect */
}
.btn-dld:hover::before,
.btn-white:hover::before,
.btn-primary:hover::before {
    transform: scale(3);
    opacity: 0;
}
.btn-dld:hover,
.btn-white:hover,
.btn-primary:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}
.btn-white {
  padding-right:33px;
  padding-left:33px;
  min-height: 58px;
  background-color: #FFF;
  border-radius: 5px;
  color: #00A295;
  border: 2px solid #00A295;
  font-size: 23px;
  font-weight: 700;
  transition: box-shadow 0.3s ease; /* Add transition for smooth effect */
}

.btn-dld {
  min-width: 171px;
  min-height: 41px;
  background: #00A295;
  color: white;
  border-radius: 4px;
  border: 2px solid #FFF;
  font-size: 15px;
  font-weight: 600;
  transition: box-shadow 0.3s ease; 
}

.banner {
  display: flex;
  justify-content: start;
}

.dash-board-content {
  font-size: 48px;
  font-weight: 900;
  font-family: "Inter";
  line-height: 58px;
  max-width:648px;
}
.dash-board-insights{
  font-size: 48px;
  font-weight: 700;
  font-family: "Inter";
  line-height: 58px;
}
.d-1{
  padding-left:58px;
  padding-right:58px;
}
.dash-para {
  font-size: 22px;
  font-weight: 300;
  font-family: "Inter";
  line-height: 36px;
  color: #24303F;
}

.font-p {
  font-size: 15px;
  color: #24303F;
  font-weight: 400;
}
.font-p-features {
  font-size: 24px;
  color: #24303F;
  font-weight: 400;
}

td.text-center.font-p {
  font-size: 15px;
  font-weight: 400;
  line-height: 58px;
  font-family: 'Inter';
}

.table>thead {
  vertical-align: bottom;
  text-align: center;
  background: #E8E8E8;
}

.game-changer-message {
  background: #24303F;
  color: #FFF;
  padding-top: 53px;
  padding-bottom: 58px;
}

.dash-headers {
  font-size: 45px;
    font-family: 'Inter';
    font-weight: 700;
    line-height: 58px;
    color: #FFF;
}

.dash-cards {
  background: #24303F;
  color: #FFF;
  box-shadow: 0px 4px 15px 1px #0000002E;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66%;
  border-radius: 10px;
}

.dash-head2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  text-align: center;
}

.dash-head3 {
    font-weight: 900;
    font-size: 48px !important;
    line-height: 58px;
}
.dash-head5 {
  font-weight: 600;
  font-size: 48px !important;
  line-height: 58px;
}
.dash-footer {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  font-family: "Inter";
}

.dash-cards-3 {
  background: #24303F;
  min-height: 227px;
  display: flex;
  color: #FFF;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:25%;
  border-radius: 10px;
}

.dash-cards1 {
  background: #FFFFFF;
  width: 66%;
  display: flex;
  box-shadow: 0 2px 4px rgba(14, 30, 37, .12);
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
}

.dash-cards4 {
  background: #FFFFFF;
  width: 75%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(14, 30, 37, .12);
  justify-content: center;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
}

.flex-item {
  display: flex;
  gap: 30px;

}

.dash-cards-2 {
  background: #FFF;
  padding: 24px;
  width: 33%;
  box-shadow: 0 2px 4px rgba(14, 30, 37, .12);
  border-radius: 10px;
}

.dash-img-2 {
  height: 129px;
  display: flex;
  justify-content: center;
}

.card-para {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 22px;
}
td.text-left.card-para {
  padding-left: 29px;
  padding-top: 16px;
}
.last-row td {
  font-weight: 600;
}
.card-para1 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 26px;
  color: #000000;
}

.dash-head1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  font-family: "Inter";
}

.btn-black {
  min-width: 81px;
  min-height: 25px;
  background: #24303F;
  color: #FFF;
  border-radius: 4px;
  border: none;
  font-size: 13px;
  font-weight: 400;
}

.button.btn-black {
  background: #24303F;
  color: #FFF;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding: 4px 23px 4px 23px;
  min-width: 176px;
}

.btn-green {
  min-width: 130px;
  background: #00A295;
  color: #FFF;
  border-radius: 4px;
  border: none;
  font-size: 15px;
  font-weight: 600;
}

.image-Acess {
  display: flex;
  justify-content: center;
}

.shody-online {
  font-weight: 700;
  font-size: 40px;
  line-height: 34px;
  text-align: center;
}

.dash-ser {
  font-size: 30px;
  font-weight: 700;
  font-family: "Inter";
  line-height: 34px;
}

.list-fonts {
  font-size: 15px;
  font-weight: 400;
  line-height: 34px;
}

.ul-list {
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dash-text-secound {
  font-weight: 700;
  font-size: 64px;
  font-family: "Inter";
  line-height: 76px;
}

.btn-white-flex {
  background: #FFFFFF;
  border: none;
  color: #232F3E;
  min-height: 32px;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0px 4px 9px 0px #00000033;
}

.btns-acess {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
}

.platform-1 {
  background: #24303F;
  color: white;
  display: flex;
  padding-top: 76px;
  padding-bottom: 72px;
  padding-left: 163px;
}

.platform-2 {
  background: #EFF6F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imge-banner {
  height: 315px;
}

.logo {
  width: 184px;
    height: auto;
    margin-right: 80px;
}

.v-harvest {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 22px;
}

ul li {
  list-style: none;
  font-size: 15px;
  font-weight: 500;
  font-family: Inter;
}

.p-0 .f-1 {
  display: flex;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid #24303F;
    padding-bottom: 7px;
    margin-bottom:37px;

}
.f-3{
  font-weight:600;
  font-size:20px;
  line-height:34px;
  color:#24303F;
}
.f-2{
  font-size: 40px;
    color: #00A295;
    font-family: Lobster Two;
    font-weight: 700;
    line-height: 34px;
    /* border: 2px solid #000000; */
    /* border-color: black; */
    width: 14px;
   filter: drop-shadow(7px 3px 4px rgba(0, 0, 0, 0.4));
    /* stroke: black; */
    margin: 0;
    -webkit-text-stroke: 2px #000;
}
.font-access {
  font-size: 46px;
    font-weight: 700;
    line-height: 26px;
    color: white;
}

.bggray {
  background: linear-gradient(to right, #24303F 50%, #EFF6F5 50%);
  /* Set gradient background */
}

.wrap-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrap-item {
  min-width: 20%;
  /* Adjust the width to fit five items in a row */
  margin-bottom: 20px;
  /* Add margin as needed */
  text-align: center;
  /* Center the content */
}

.footer-main {
  background: #24303F;
  margin-top: -1px;
}

.flex-item-footer {
  display: flex;
  justify-content: space-between;
  color: #FFF;
  flex-wrap: wrap;
}

.font-height {
  display: flex;
  background-color: #00A295;
  align-items: end;
  margin-top: -1px;
}

.footer-f li {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}

.f-1 {
  font-size: 18px;
  color: #FFF;
  font-weight: 400
}

.v-1 {
  font-size: 25px;
  font-weight: 600;
}

.title-btn {
  font-size: 20px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  margin-top:15px;
  line-height:34px;
}

.togle {
  display: none;
}

.dash-cards-2:hover,
.dash-cards1:hover,
.dash-cards-3:hover,
.dash-cards4:hover {
  transform: scale(1.03);
  transition-duration: 0.6s; /* Set transition duration for hover effect */
  transition-timing-function: cubic-bezier(0.64, 0.15, 0.53, 0.53); /* Use custom timing function */
}
.dash-cards-3,
.dash-cards-2,
.dash-cards1,
.dash-cards4 {
  transition-duration: 0.6s; /* Set transition duration for initial state */
  transition-timing-function: cubic-bezier(0.64, 0.15, 0.53, 0.53); /* Use custom timing function */
}

.img {
  transition: transform 150ms, opacity 150ms;
  transition-duration: .6s;
  /* Add transition for smooth effect */
  transform: scale(1);
  /* Initial scale */
}
.img:hover {
  transform: scale(1.1);
  z-index:10;
  /* Scale image on hover */

}
.cl-4{
  padding-right:83px;
}
.nav-item a {
  color: #313450;
  text-decoration: none;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}

.nav-item a:hover {
  color: #00A295;
  transition: width 0.3s;
}

li.nav-item .active {
  color: #00A295;
}

.nav-item a.active::after,
.nav-item a:hover::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #00A295;
  display: block;
  transition: width 0.3s;
  /* Include transition for both active and hover states */
}
.hero-image {
  animation: hero_transition 2.5s ease-in-out infinite alternate-reverse both;
}
.anim-1 {
  transition: transform 150ms, opacity 150ms;
  transition-duration: .6s;
}
.anim-1:hover {
  transform: scale(1.03);
  z-index:10;
}
.anim-2 {
  transition-duration: 0.6s; /* Set transition duration for initial state */
  transition-timing-function: cubic-bezier(0.64, 0.15, 0.53, 0.53); /* Use custom timing function */
}
.anim-2:hover{
  transform: scale(1.03);
  transition-duration: 0.6s; /* Set transition duration for hover effect */
  transition-timing-function: cubic-bezier(0.64, 0.15, 0.53, 0.53); /* Use custom timing function */
}
.anim-3{
  transition: transform 150ms, opacity 150ms;
  transition-duration: .6s;
  /* Add transition for smooth effect */
  transform: scale(1);
}
.anim-3:hover{
  transform: scale(1.1);
  z-index:10;
  /* Scale image on hover */
}
.anim-4{
  opacity: 1;
    transform: translateY(0px);
    transition: opacity 2s ease 0s, transform 2s ease 0s;
}
.btn-block{
  width:100%;
}
button.btn-block.btn-feedback {
  padding: 10px;
  border-radius: 4px;
  background: #FFF;
  border: 1px solid #DDD;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight:700;
}
nav div a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 700;
  font-family: Inter;
  color: #24303F;
}
nav div div {
  padding:12px;
  border-bottom:1px solid #DDD;
}
.scrollable-element {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 2s ease 0s, transform 2s ease 0s;
}

.scrollable-element.visible {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 767.98px) {
  .dash-cards-2:hover,
  .dash-cards1:hover,
  .dash-cards-3:hover,
  .dash-cards4:hover {
    transform: none;
    transition-duration: 0s;
    transition-timing-function: initial;
  }
  .img:hover {
    transform: scale(1); /* Reset the transform on hover for mobile devices */
    z-index: initial; /* Resetting the z-index to its default value */
  }
  .anim-1:hover {
    transform: none;
    z-index: initial; /* Resetting the z-index to its default value */
  }
  .dash-para {
    font-size: 13px;
  }
  .cl-4{
    padding-right:83px;
  }
  .game-changer-message {
    padding-top: 23px;
  }

  .header {
    margin-bottom: 30px;
  }

  /* Styles here will be applied when the viewport width is 768px or less */
  .mk-1 {
    margin-top: 42px;
  }

  .c-1 {
    text-align: left;
  }

  .dash-headers {
    font-size: 18px;
    line-height: 31px;
  }

  .dash-cards {
    width: 100%;
    min-height: 128px;
  }

  .dash-cards-2 {
    width: 100%;
  }

  .dash-text-secound {
    font-size: 18px;
    line-height: 34px;
  }

  .dash-cards1 {
    width: 100%;
    padding: 24px;
  }

  .flex-item {
    flex-wrap: wrap;
  }

  .ot-1 {
    order: 1
  }

  .dash-cards4 {
    width: 100%;
    padding: 24px;
  }

  .dash-cards-3 {
    width: 100%;
    min-height: 173px;
  }

  .font-access {
    font-size: 18px;
  }

  .shody-online {
    font-size: 18px;
  }

  .dash-ser {
    font-size: 18px;
  }

  .flex-m {
    display: flex;
    justify-content: center;
  }

  .btns-acess {
    flex-direction: column;
    gap: 16px
  }

  .wrap-item {
    min-width: 50%;
  }

  .list-fonts {
    font-size: 13px;
  }

  .dash-head1 {
    font-size: 18px;
  }

  .dash-board-content {
    font-size: 18px;
  }

  .f-1 {
    font-size: 13px;
  }

  .t-1 {
    text-align: center;
  }

  .v-1 {
    font-size: 15px;
  }

  .font-p {
    font-size: 13px;
  }

  .dash-head3 {
    font-size: 18px;
    text-align: left;
  }

  .img-width {
    width: 100%;
  }

  ol li {
    font-size: 13px;
  }

  .flex-item-footer {
    margin-top: -1px;
  }

  .title-btn {
    font-size: 18px;
  }

  nav ul li {
    display: none;
  }

  .togle {
    display: block;
  }

  .j-m {
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgba(0, 0, 0, .12), 0px 5px 5px -3px rgba(0, 0, 0, .2);
    box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgba(0, 0, 0, .12), 0px 5px 5px -3px rgba(0, 0, 0, .2);
    font-family: "Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    line-height: 20px;
    padding: 5px;
    position: absolute;
    top: 29px;
    background: #FFF;

  }

  .j-m ul li {
    display: flex;
    flex-direction: column;
    gap: 10px
  }

  .bggray {
    background: linear-gradient(to right, #24303F 100%, #EFF6F5 100%);
    /* Set gradient background */
  }
}

@media (max-width: 576px) {

  /* Adjusted for small screens */
  .wrap-item {
    min-width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}
@keyframes hero_transition {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}