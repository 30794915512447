// styles/index.scss

// Import variables and other SCSS features
@import '_variables';

// Use variables, mixins, placeholders, functions, and other SCSS features as needed

.about {
  // @include flex-layout(column, start, center, nowrap); // Using the flex-layout mixin with specified parameters

  h2 {
    font-size: 24px;
    font-weight: bold;
    color:$text-color;
  }
    p {
      font-size: 14px;
    font-weight: 400;
    font-family: "Inter";
    line-height: 26px;
    color: #000000;
    }

    a {
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        color: darken($primary-color, 10%);
      }
    }
  }

// Other styles...
