// styles/_variables.scss

// Colors
$primary-color: rgb(0, 162, 149);
$secondary-color: #24303F;;
$text-color:#FFF;

// Mixin for flex layout with parameters
@mixin flex-layout($direction: row, $justify-content: center, $align-items: center, $flex-wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

// Placeholder for border radius
%border-radius {
  border-radius: 4px;
}

// Function for calculating width
@function calc-width($value) {
  @return $value * 2;
}

// Nested style

// Other variables, mixins, functions, etc.

// Any additional SCSS features can be documented here
