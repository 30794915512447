.modal {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity 0.3s ease; /* Fade-in transition for the modal overlay */
  }
  
  .modal.open {
    display: block;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: inherit;
    height: 100%;
    left: -100%;
    position: absolute;
    top: 0;
    transition: left .3s ease;
    width: 100%;
    border: none;
}
  
  .modal.open .modal-content {
    left: 0; /* Slide modal content into view */
  }
  